import tailwind from "@theme-ui/preset-tailwind";
import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState } from "react";
/** @jsx jsx */
import { Button, Input, jsx, Label, Spinner } from "theme-ui";
import useLocalStorage from "../hooks/use-localstorage";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isSubscribed, setIsSubscribed] = useLocalStorage('isSubscribed', false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    setMessage({});
    const result = await addToMailchimp(email, {}, null);
    if (result && result.result === "error") {
      setMessage({type: 'error', text: result.msg});
    } else {
      setMessage({type: 'success', text: "Success! Thanks for subscribing!"});
      setIsSubscribed(true);
    }
    setSubmitted(false);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (isSubscribed && typeof message.text === 'undefined') ? null : (
    <div sx={{ variant: "inlineForm" }}>
      { message.type === 'success' ?
      (        <div
        sx={{
          color: message.type === 'error' ? tailwind.colors.red[6] : tailwind.colors.green,
          display: message.text === "" ? "none" : ["block", "block", "flex"],
          alignItems: `center`,
          justifyContent: `center`,
        }}
        dangerouslySetInnerHTML={
          message.text === "" ? { __html: "" } : { __html: message.text }
        }
      ></div>) :
      (<form onSubmit={handleSubmit}>
        <div sx={{ display: `flex`, alignItems: `center`, justifyContent: `center`, flexDirection: [`column`, `row`, `row`] }}>
          <div>
            <Label>Sign up to get updates</Label>
          </div>
          <div sx={{ display: `flex`, flexDirection: `column` }}>
            <Input
              type="email"
              onChange={handleChange}
              placeholder="Enter your email"
            />
          </div>
          {submitted ? (
            <div sx={{ display: `flex` }}>
              <Spinner />
            </div>
          ) : (
            <div sx={{mt: [`0.5rem`, `0`, `0`]}}>
              <Button type="submit" onClick={handleSubmit} variant="primary">
                SUBSCRIBE
              </Button>
            </div>
          )}
        </div>
        <div
          sx={{
            color: tailwind.colors.red[6],
            display: message.text === "" ? "none" : ["block", "block", "flex"],
            alignItems: `center`,
            justifyContent: `center`,
          }}
          dangerouslySetInnerHTML={
            message.text === "" ? { __html: "" } : { __html: message.text }
          }
        ></div>
      </form>)}
    </div>
  );
};

export default Subscribe;
